<template>
  <div class="config-property">
    <div>
      {{ label }}
    </div>
    <input type="text" v-model="value[config]" class="form-control">

  </div>
</template>

<script>
export default {
  name: 'BOOLEAN_COMPONENT',
  props: [
    'value',
    'config',
    'label',
  ],
};
</script>