<template>
    <div class="config-property">
        <div>
            Tipo de compra por marca utilizado:
        </div>
        <label class="input-radio" v-if="!!value.TIPO_COMPRE_MARCA">
            <strong>Texto para ser utilizado na compra por marca (deixe em branco se não deseja utilizar)</strong>
            <input type="text" v-model="value.TEXTO_COMPRE_MARCA" class="form-control">
        </label>
        <label class="input-radio" v-for="(tipo, idx) in tipos" :key="idx">
            <div class="row">
                <div class="col-sm-1">
                    <input type="radio" :value="tipo.value" name="TIPO_COMPRE_MARCA" v-model="value.TIPO_COMPRE_MARCA">
                </div>
                <div class="col-sm-11">
                    {{ tipo.label }} <br>
                    <img :src="tipo.img">
                </div>
            </div>
        </label>
    </div>
</template>

<script>
  import IMG_MARCA_COM_BORDA from '../img/MARCA_COM_BORDA.png';
  import IMG_MARCA_SEM_BORDA from '../img/MARCA_SEM_BORDA.png';

  export default {
    name: "TIPO_COMPRE_MARCA",
    props: [
      'value'
    ],
    data() {
      return {
        tipos: [
          {
            label: 'Com bordas de lateral a lateral texto centralizado',
            value: 'MARCA_COM_BORDA',
            img: IMG_MARCA_COM_BORDA,
          },
          {
            label: 'Sem bordas e texto alinhado a esquerda',
            value: 'MARCA_SEM_BORDA',
            img: IMG_MARCA_SEM_BORDA,
          },
        ]
      }
    }
  }
</script>