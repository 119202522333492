<template>
    <div class="config-property">
        <div>
            Tipo de pré-cabeçalho utilizado:
        </div>
        <label class="input-radio" v-for="(tipo, idx) in tipos" :key="idx">
            <div class="row">
                <div class="col-sm-1">
                    <input type="radio" :value="tipo.value" name="TIPO_PRE_CABECALHO" v-model="value.TIPO_PRE_CABECALHO">
                </div>
                <div class="col-sm-11">
                    {{ tipo.label }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>
  export default {
    name: "TIPO_PRE_CABECALHO",
    props: [
      'value'
    ],
    data() {
      return {
        tipos: [
          {
            label: 'Texto personalizado',
            value: 'TEXT',
          },
          {
            label: 'Links',
            value: 'LINKS',
          },
        ]
      }
    }
  }
</script>