<template>
    <div>
        <div>
            Tipo de de ação no cabeçalho:
        </div>
        <label class="input-radio" v-for="(tipo, idx) in tipos" :key="idx">
            <div class="row">
                <div class="col-sm-1">
                    <input type="radio" :value="tipo.value" name="LINK_PARA_ACAO" v-model="value.LINK_PARA_ACAO">
                </div>
                <div class="col-sm-11">
                    {{ tipo.label }}
                </div>
            </div>
        </label>
    </div>
</template>

<script>
  import IMG_MARCA_COM_BORDA from '../img/MARCA_COM_BORDA.png';
  import IMG_MARCA_SEM_BORDA from '../img/MARCA_SEM_BORDA.png';

  export default {
    name: "LINK_PARA_ACAO",
    props: [
      'value'
    ],
    data() {
      return {
        tipos: [
          {
            label: 'Abrir modal para informar número de telefone',
            value: 'MODAL_TELEFONE',
          },
          {
            label: 'Chamar no whatsapp',
            value: 'CALL_WHATSAPP',
          },
          {
            label: 'Nenhuma ação',
            value: 'NENHUM',
          },
        ]
      }
    }
  }
</script>